<template>
    <div>
        <div class="col-auto mb-2">
            <div class="border p-2 card-asistencia">
                <b-row class="justify-content-around border-bottom pb-1">
                    <div class="d-flex align-items-center">
                        <feather-icon icon="HashIcon" size="20"></feather-icon>
                        <span class="font-medium-4 ml-50">{{
                            asistencia.id
                        }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <feather-icon
                            icon="CalendarIcon"
                            size="20"
                        ></feather-icon>
                        <span class="font-medium-4 ml-50">22 ago. 2022</span>
                        <p class="font-small-3">02:20 am</p>
                    </div>
                    <feather-icon
                        v-if="asistencia.estado === 0"
                        icon="CalendarIcon"
                        size="35"
                        class="bg-gradient-warning round p-50"
                    ></feather-icon>
                    <feather-icon
                        v-if="asistencia.estado === 1"
                        icon="CheckIcon"
                        size="35"
                        class="bg-gradient-success round p-25"
                    ></feather-icon>
                    <feather-icon
                        v-if="asistencia.estado === 2"
                        icon="RotateCcwIcon"
                        size="35"
                        class="bg-gradient-info round p-50"
                    ></feather-icon>
                    <feather-icon
                        v-if="asistencia.estado === 3"
                        icon="XIcon"
                        size="35"
                        class="bg-gradient-danger round p-25"
                    ></feather-icon>
                </b-row>
                <b-row class="pt-1">
                    <b-col cols="auto">
                        <h4>Profesional</h4>
                        <p>Oscar Acelas</p>
                        <h4>Entidad</h4>
                        <p>Asmed Salud</p>
                    </b-col>
                    <b-col cols="auto">
                        <h4>Dispositivos</h4>
                        <div class="mb-1">
                            <feather-icon
                                icon="HardDriveIcon"
                                size="18"
                            ></feather-icon>
                            <span class="px-1 tres-puntos">Blood pressure</span>
                            <b-badge variant="light-success" pill
                                >Normal</b-badge
                            >
                        </div>
                        <div class="mb-1">
                            <feather-icon
                                icon="HardDriveIcon"
                                size="18"
                            ></feather-icon>
                            <span class="px-1 tres-puntos"
                                >Body composition</span
                            >
                            <b-badge variant="light-danger" pill>Grave</b-badge>
                        </div>
                        <div class="mb-1">
                            <feather-icon
                                icon="HardDriveIcon"
                                size="18"
                            ></feather-icon>
                            <span class="px-1 tres-puntos">Weight scale</span>
                            <b-badge variant="light-warning" pill
                                >Regular</b-badge
                            >
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    props: {
        asistencia: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, context) {
        return {};
    },
};
</script>
<style lang="css" scope>
.card-asistencia {
    min-width: 400px;
    border-radius: 20px;
}
</style>
